import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return [ "content" ]
  }

  copy(evt) {
    window.getSelection().removeAllRanges();
    let range = document.createRange();
    range.selectNode(this.contentTarget);
    window.getSelection().addRange(range);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();

  }
}
